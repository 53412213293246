import React from "react";

export default function LegalMentions() {
  const list = {
    marginBottom: 70,
  };
  const m2 = {
    marginBottom: 50,
  };
  return (
    <div>
      <div className="container-title about">
        <h1>Mentions Légales</h1>
      </div>

      <div className="info">
        <h2>Dynamic' Assainissement</h2>
        <ul style={list}>
          <li>Forme juridique: SASU</li>
          <li>Adresse: 22 AV DU GENERAL LECLERC 94470 BOISSY-SAINT-LEGER</li>
          <li>SIRET: 90794431800019</li>
          <li>Numéro TVA Intracommunautaire: FR22907944318</li>
        </ul>

        <div style={m2}>
          <h2>Nature et utilisation des données personnelles recueillies :</h2>
          <p>
            Les informations que nous sommes amenées à recueillir proviennent de
            l’enregistrement volontaire d’une adresse e-mail vous permettant de
            recevoir votre devis ou bien de nous passer commande.
          </p>
          <p>
            L’ensemble des données collectées demeurent à l’usage interne de
            Dynamic' Assainissement qui est l’unique propriétaire des
            informations recueillies sur le site
            https://dynamic-assainissement.fr/.
          </p>
          <p>
            “Conformément aux articles 39 et suivants de la loi n° 78-17 du 6
            janvier 1978 relative à l’informatique, aux fichiers et aux
            libertés, vous disposez des droits d’opposition, d’accès et de
            rectification des données vous concernant. Ainsi, vous pouvez exiger
            que soient rectifiées, complétées, clarifiées, mises à jour ou
            effacées les informations vous concernant qui sont inexactes,
            incomplètes, équivoques, périmées ou dont la collecte ou
            l’utilisation, la communication ou la conservation est interdite.
            Pour cela adressez votre demande par courrier à Dynamic'
            Assainissement.”
          </p>
        </div>

        <div>
          <h2>Article L122-4 du code de la Propriété Intellectuelle</h2>
          <p>
            “Toute représentation ou reproduction intégrale ou partielle faite
            sans le consentement de l’auteur ou de ses ayants droit ou ayants
            cause est illicite. Il en est de même pour la traduction,
            l’adaptation ou la transformation, l’arrangement ou la reproduction
            par un art ou un procédé quelconque.
          </p>
        </div>
      </div>
    </div>
  );
}
